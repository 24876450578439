* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

}
body {
  margin: 0px;
  background: linear-gradient(200deg, #004AAD, #38B6FF, #004AAD, #004AAD);
  background-size: 240% 240%;
  animation: gradient-animation 12s ease infinite;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #004AAD;
}
h2{
  margin: 5px 0px;
}
p{
  margin: 0px;
}
span{
  font-size: 12px;
  padding-top: 20px;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.main {
  width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: relative;
}

.main img {
  margin: 0px auto;
}
.social-media {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}
.logo {
  position: absolute;
  height: 80px;
  width: 90px;
  border-radius: 20px;
  bottom: 0;
  left: 0;
  background-image: url("./logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
}
.social-media a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  color: white;
  background-color: black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .main {
    width: 80%;
  }
}